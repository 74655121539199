<template>
  <section id='docs-payments-integration-process-payment-api-flow'>
    <v-card-subtitle>
      This guide furnishes detailed documentation for integrating subscription creation and initial
      payment process into your platform seamlessly through HTTP API requests. This approach enables
      programmatic interaction with our platform, providing flexibility and customization options.
      You can tailor the payment experience to meet the specific requirements of your platform,
      ensuring a seamless and personalized subscription integration.
    </v-card-subtitle>

    <v-card-text>
      Following the successful creation of the payment session, as outlined in the initial step, it
      is crucial to securely collect the customer's payment information. This encompasses sensitive
      details like the card number, cardholder name, expiration date, and security code.
    </v-card-text>

    <v-card-text>
      To initiate the payment, securely transmit the collected data using the <code>POST</code>
      method to the
      <code>/payment-subscriptions/process</code> endpoint on Merchanto platform. Remember to
      include the <code>Api-Key</code> header with your API key in the request. Ensure that the
      payload includes both the <code>paymentSessionId</code> and the card data for a comprehensive
      payment processing experience.
    </v-card-text>

    <v-card-title><span class='subtitle-1'>Code example:</span></v-card-title>
    <v-card-text>
      <v-tabs v-model='processSubscriptionCodeExampleTab' class='code-examples-tabs'>
        <v-tab>Node.js</v-tab>
        <v-tab>PHP</v-tab>
      </v-tabs>

      <v-tabs-items v-model='processSubscriptionCodeExampleTab'>
        <v-tab-item>
          <NodeProcessSubscriptionExample />
        </v-tab-item>
        <v-tab-item>
          <PhpProcessSubscriptionExample />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <v-card-text>
      Ensure the timing aligns with the 10-minute session lifetime. If processing attempts occur
      beyond this window, an error will be triggered, indicating session expiration. The session
      will
      automatically extend by 10 minutes with each unsuccessful attempt, offering flexibility for
      customers to correct errors and ensure a smooth payment experience.
    </v-card-text>

    <v-card-title>Response Details</v-card-title>
    <v-card-text>
      The response from the /payment-subscription/process endpoint will contain the following
      fields:
      <ul class='mb-2'>
        <li>
          <code>subscriptionToken</code> - Is a unique identifier for the subscription generated by
          the Merchanto platform. It should be saved for future interactions with the current
          subscription
        </li>
        <li>
          <code>subscriptionStatus</code> - Possible Values: <code><strong>active</strong></code>
          (indicates that payment data was saved, the payment was successful, and the subscription
          is active), <code><strong>inactive</strong></code> (represents a scenario where
          something went wrong. The subscription was created but not activated), <code><strong>enrolled_3ds</strong></code>
          (signifies that 3D secure verification is required for the subscription)
        </li>
        <li>
          <code>paymentId</code> - The identifier of the payment, generated by the Merchanto
          platform
        </li>
        <li>
          <code>orderId</code> - The original order ID provided by your system to the
          <code>/create</code> endpoint
        </li>
        <li>
          <code>redirectUrl</code> - The link provided to the <code>/create</code> endpoint for
          post-payment redirection
        </li>
        <li>
          <code>paymentStatus</code> - Possible Values: <code><strong>completed</strong></code>
          (successful payment), <code><strong>failed</strong></code> (payment failure),
          <code><strong>enrolled_3ds</strong></code> (3D secure verification required)
        </li>
        <li>
          <code>secureRedirect</code> - optional, appears only if paymentStatus is <code><strong>enrolled_3ds</strong></code>.
          This field contains the link where you should redirect the customer to complete the 3D
          secure verification.
          After the 3D secure verification process is completed, the customer will be automatically
          redirected to the <code>redirectUrl</code> provided to the <code>/create</code> endpoint.
        </li>
      </ul>
    </v-card-text>

    <v-card-text><strong>Note:</strong> For more detailed information about possible responses and
      further API reference, please refer to the dedicated API reference section of this
      documentation.
    </v-card-text>
  </section>
</template>

<script>
import {
  NodeProcessSubscriptionExample,
  PhpProcessSubscriptionExample,
} from '../code-examples';
import { ref } from '@vue/composition-api';

export default {
  components: { NodeProcessSubscriptionExample, PhpProcessSubscriptionExample },
  setup() {
    const processSubscriptionCodeExampleTab = ref(null);

    return {
      processSubscriptionCodeExampleTab,
    };
  },
};
</script>
