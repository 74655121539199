<template>
  <section id='docs-subscriptions-renew-subscription'>
    <v-card-title>
      <h3 class='text-break'>Renew Subscription</h3>
    </v-card-title>

    <v-card-subtitle>
      This documentation provides a comprehensive guide on renewing a previously canceled
      subscription through the Merchanto platform. It is essential to note that subscription
      renewals can only be performed on subscriptions that have been canceled.
    </v-card-subtitle>

    <v-card-title>When to Renew a Subscription:</v-card-title>
    <v-card-text>
      <ul class='mb-2'>
        <li>
          Renewing a subscription becomes relevant in scenarios where a customer wishes to reinstate
          a previously canceled subscription. This action allows customers to resume the
          subscription and ensures that recurrent payments can be charged once again.
        </li>
      </ul>
    </v-card-text>

    <v-card-title>How to Renew a Subscription:</v-card-title>
    <v-card-text>
      <ul class='mb-2'>
        <li>
          <strong>Verify Subscription Status:</strong>
          <p class='text-break'>Ensure that the subscription you intend to renew has been canceled.
            Attempting to renew an active subscription will not be valid.</p>
        </li>
        <li>
          <strong>Initiate Renewal Request:</strong>
          <p class='text-break'>Make a request to the appropriate Merchanto endpoint designed for
            renewing subscriptions. Include the <code>subscriptionToken</code> to the request
            parameters.</p>
        </li>
        <li>
          <strong>Processing the Renewal:</strong>
          <p class='text-break'>Upon a successful request, Merchanto will process the renewal, and
            the subscription status will be updated accordingly. Ensure that your system handles the
            response appropriately and provides necessary notifications to the customer.</p>
        </li>
        <li>To renew a subscription, you need to make a request to the <code>/payment-subscriptions/renew</code>
          endpoint. This request should include the following parameters:
          <ul>
            <li><code>subscriptionToken</code> - The unique identifier for the subscription
              generated by the Merchanto platform
            </li>
          </ul>
        </li>
      </ul>
      <pre class='output'><code v-pre>
    {
      "subscriptionToken": "5a8e797a-0363-40e4-9dee-a78e541b7835",
    }
      </code></pre>
    </v-card-text>

    <v-card-title>Handling Response:</v-card-title>
    <v-card-text>
      <ul class='mb-2'>
        <li>The response from the Merchanto platform will include details such as:
          <ul>
            <li><code>subscriptionToken</code> - The unique identifier for the subscription
              generated by the Merchanto platform
            </li>
            <li><code>subscriptionStatus</code> - The updated status of the subscription. Possible
              values include <code><strong>"active"</strong></code> if the subscription was renewed
              and <code><strong>"canceled"</strong></code> if some error occurred during renewal
              process and subscription still remains cancelled.
            </li>
          </ul>
        </li>
      </ul>
      <pre class='output'><code v-pre>
    {
      "subscriptionToken": "5a8e797a-0363-40e4-9dee-a78e541b7835",
      "subscriptionStatus": "active",
    }
      </code></pre>
    </v-card-text>

    <v-card-title><span class='subtitle-1'>Code example:</span></v-card-title>
    <v-card-text>
      <v-tabs v-model='renewSubscriptionCodeExampleTab' class='code-examples-tabs'>
        <v-tab>Node.js</v-tab>
        <v-tab>PHP</v-tab>
      </v-tabs>

      <v-tabs-items v-model='renewSubscriptionCodeExampleTab'>
        <v-tab-item>
          <NodeRenewSubscriptionExample />
        </v-tab-item>
        <v-tab-item>
          <PhpRenewSubscriptionExample />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <v-card-text>
      <p class='text-break'><strong>Important:</strong> Subscription renewals are applicable only to
        subscriptions that have been previously canceled. Ensure that customers are informed about
        the renewal process and the implications on their subscription status.
      </p>
      <p class='text-break'><strong>Note:</strong> For more detailed information about possible
        responses and error handling, refer to the API reference section of this documentation.</p>
    </v-card-text>
  </section>
</template>

<script>
import {
  NodeRenewSubscriptionExample,
  PhpRenewSubscriptionExample,
} from './_partials/code-examples';
import { ref } from '@vue/composition-api';

export default {
  components: { NodeRenewSubscriptionExample, PhpRenewSubscriptionExample },
  setup() {
    const renewSubscriptionCodeExampleTab = ref(null);

    return {
      renewSubscriptionCodeExampleTab,
    };
  },
};
</script>
