<template>
  <section id='docs-subscriptions-integration'>
    <v-card-title>
      <h3 class='text-break'>Subscriptions Integration</h3>
    </v-card-title>

    <ObtainingApiKey />

    <CreatePaymentSession />

    <v-card-title id='docs-subscriptions-integration-process-payment'>
      <h4 class='text-break'>&#10123; Process Initial Payment</h4>
    </v-card-title>

    <ProcessSubscriptionApiFlow />

    <ResultPageSetup />
  </section>
</template>

<script>
import { ref } from '@vue/composition-api';
import {
  CreatePaymentSession,
  ObtainingApiKey,
  ProcessSubscriptionApiFlow,
  ResultPageSetup,
} from './_partials/documentation-blocks';

export default {
  components: {
    CreatePaymentSession,
    ResultPageSetup,
    ObtainingApiKey,
    ProcessSubscriptionApiFlow,
  },

  setup() {
    const integrationGuideTab = ref(null);

    return {
      integrationGuideTab,
    };
  },
};
</script>
