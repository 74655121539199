<template>
  <div id='knowledge-base'>
    <v-row>
      <v-col cols='12' md='3' lg='3' order='1' order-md='2' class='knowledge-base-summary-overlay'>
        <v-card class='knowledge-base-summary'>
          <div class='kb-questions py-1'>
            <div v-for='paragraph in subscriptionsIntegrationSummary' :key='paragraph.title'>
              <div class='kb-question d-flex align-start text-sm px-5 my-4 cursor-pointer'>
                <v-icon size='14' class='me-2 mt-1' color='secondary'>
                  {{ icons.mdiFileDocumentMultiple }}
                </v-icon>
                <span class='text--secondary'
                      @click='scrollToElement(paragraph.link)'>{{ paragraph.title }}</span>
              </div>

              <ul v-if='paragraph.children'>
                <li v-for='subparagraph in paragraph.children'
                    :key='subparagraph.title'
                    class='kb-question d-flex align-start text-sm px-5 my-4 cursor-pointer'>
                  <v-icon size='14' class='me-2 mt-1' color='secondary'>
                    {{ icons.mdiTextBoxOutline }}
                  </v-icon>
                  <span class='text--secondary'
                        @click='scrollToElement(subparagraph.link)'>{{ subparagraph.title }}</span>
                </li>
              </ul>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col cols='12' md='9' lg='9' order='1' order-md='2'>
        <v-card>
          <v-card-title>
            <h2 class='text-break'>Merchanto Subscriptions</h2>
          </v-card-title>
          <v-card-title>
            <h5 class='text-break mt-2'>Easily integrate subscription payments to your site.</h5>
          </v-card-title>

          <v-card-subtitle class='py-2'>
            <p class='text-break'>This guide offers a comprehensive overview of integrating
              subscription payments into your platform using the Merchanto API. The process is
              straightforward: initiate a payment session and process the initial payment (which can
              be zero). Use the API for greater control. The Merchanto platform securely saves
              payment data on the payment acquirer’s side, ensuring no card details are stored on
              Merchanto. After processing the payment, a unique subscription token is generated and
              included in the response. Be sure to save this token for future subscription charges
              via the Merchanto API.</p>

            <p class='text-break'><strong>Important Considerations:</strong></p>
            <ul>
              <li>Updating Payment Information: Once a subscription is created, there is no option
                to update payment information. If payment details change (e.g., credit card
                expiration), you should create a new subscription. This new subscription may require
                3D Secure authentication to maintain payment security.
              </li>
              <li>Subscription Renewal: If a subscription expires, it cannot be extended or renewed.
                To continue the service, create a new subscription with updated details if needed.
              </li>
            </ul>
          </v-card-subtitle>

          <SubscriptionsIntegration />

          <ChargeRecurrentPayment />

          <CancelSubscription />

          <RenewSubscription />

          <TestCards />

          <ApiReference />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiTextBoxOutline, mdiFileDocumentMultiple } from '@mdi/js';
import useDocs from '@/views/docs/useDocs';
import {
  ApiReference,
  CancelSubscription,
  ChargeRecurrentPayment,
  RenewSubscription,
  SubscriptionsIntegration,
  TestCards,
} from '@/views/docs/subscriptions/components';

export default {
  components: {
    ApiReference,
    CancelSubscription,
    ChargeRecurrentPayment,
    RenewSubscription,
    SubscriptionsIntegration,
    TestCards,
  },
  setup() {
    const subscriptionsIntegrationSummary = [
      {
        title: 'Subscriptions Integration', link: 'docs-subscriptions-integration', children: [
          {
            title: '1. Create Payment Session',
            link: 'docs-subscriptions-integration-create-payment-session',
          },
          {
            title: '2. Process Initial Payment',
            link: 'docs-subscriptions-integration-process-payment',
          },
          {
            title: '3. Setup Result Page',
            link: 'docs-subscriptions-integration-setup-result-page',
          },
        ],
      },
      { title: 'Charge Recurrent Payment', link: 'docs-subscriptions-charge-recurrent-payment' },
      { title: 'Cancel Subscription', link: 'docs-subscriptions-cancel-subscription' },
      { title: 'Renew Subscription', link: 'docs-subscriptions-renew-subscription' },
      {
        title: 'Test Your Integration',
        link: 'docs-subscriptions-integration-test-integration',
        children: [
          { title: '1. LPB', link: 'docs-subscriptions-integration-test-integration-lpb' },
          { title: '2. Stripe', link: 'docs-subscriptions-integration-test-integration-stripe' },
        ],
      },
      {
        title: 'API Reference', link: 'docs-subscriptions-integration-api-reference', children: [
          {
            title: '1. API URL and Endpoints',
            link: 'docs-subscriptions-integration-api-reference-url-endpoints',
          },
          {
            title: '2. Create Payment Session',
            link: 'docs-subscriptions-integration-api-reference-create-payment-session',
          },
          {
            title: '3. Process Subscription Payment',
            link: 'docs-subscriptions-integration-api-reference-process-payment',
          },
          {
            title: '4. Get Subscription details',
            link: 'docs-subscriptions-integration-api-reference-get-subscription-details',
          },
          {
            title: '5. Charge Subscription',
            link: 'docs-subscriptions-integration-api-reference-charge-subscription',
          },
          {
            title: '6. Cancel Subscription',
            link: 'docs-subscriptions-integration-api-reference-cancel-subscription',
          },
          {
            title: '7. Renew Subscription',
            link: 'docs-subscriptions-integration-api-reference-renew-subscription',
          },
        ],
      },
    ];

    const { scrollToElement } = useDocs();

    return {
      subscriptionsIntegrationSummary,
      icons: { mdiTextBoxOutline, mdiFileDocumentMultiple },
      scrollToElement,
    };
  },
};
</script>

<style lang='scss'>
@import '@core/preset/preset/pages/knowledge-base.scss';
</style>
