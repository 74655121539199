<template>
  <section id='docs-subscriptions-cancel-subscription'>
    <v-card-title>
      <h3 class='text-break'>Cancel Subscription</h3>
    </v-card-title>

    <v-card-subtitle>
      This part provides guidance on canceling a subscription using the Merchanto platform.
      Canceling a subscription is a critical operation that should be performed under certain
      circumstances. Once a subscription is canceled, there is no possibility to charge recurrent
      payments.
    </v-card-subtitle>

    <v-card-title>When to Cancel a Subscription:</v-card-title>
    <v-card-text>
      <ul class='mb-2'>
        <li>
          <strong>Customer-Requested Cancellation:</strong>
          <p class='text-break'>If a customer requests to cancel their subscription, you should
            initiate the cancellation process. This could be due to various reasons such as product
            dissatisfaction, budget constraints, or any other personal reasons.</p>
        </li>
        <li>
          <strong>Unsuccessful Recurrent Payments:</strong>
          <p class='text-break'>If recurrent payment attempts for a subscription are consistently
            unsuccessful, and there is no resolution within your system, you may consider canceling
            the subscription to prevent further unsuccessful payment attempts.
          </p>
        </li>
        <li>
          <strong>Platform Policy:</strong>
          <p class='text-break'>Your platform may have specific policies or business rules that
            require the cancellation of subscriptions under certain conditions. Ensure that you
            adhere to your platform's policies and provide a seamless experience for your users.</p>
        </li>
      </ul>
    </v-card-text>

    <v-card-title>Canceling a Subscription:</v-card-title>
    <v-card-text>
      <ul class='mb-2'>
        <li>To cancel a subscription, you need to make a request to the <code>/payment-subscriptions/cancel</code>
          endpoint. This request should include the following parameters:
          <ul>
            <li><code>subscriptionToken</code> - The unique identifier for the subscription
              generated by the Merchanto platform
            </li>
          </ul>
        </li>
      </ul>
      <pre class='output'><code v-pre>
    {
      "subscriptionToken": "5a8e797a-0363-40e4-9dee-a78e541b7835",
    }
      </code></pre>
      <p class='text-break'><strong>Important:</strong> Upon a successful cancellation request, the
        subscription status will be updated to canceled. It's important to note that once a
        subscription is canceled, there is no possibility to charge recurrent payments.</p>
    </v-card-text>

    <v-card-title>Handling Response:</v-card-title>
    <v-card-text>
      <ul class='mb-2'>
        <li>The response from the Merchanto platform will include details such as:
          <ul>
            <li><code>subscriptionToken</code> - The unique identifier for the subscription
              generated by the Merchanto platform
            </li>
            <li><code>subscriptionStatus</code> - The updated status of the subscription. Possible
              values include <code><strong>"canceled"</strong></code> if the subscription was
              canceled, and there is no possibility to charge recurrent payments and <code><strong>"active"</strong></code>
              if some error occurred during cancellation process and subscription still remains
              active
            </li>
          </ul>
        </li>
      </ul>
      <pre class='output'><code v-pre>
    {
      "subscriptionToken": "5a8e797a-0363-40e4-9dee-a78e541b7835",
      "subscriptionStatus": "canceled",
    }
      </code></pre>
    </v-card-text>

    <v-card-title><span class='subtitle-1'>Code example:</span></v-card-title>
    <v-card-text>
      <v-tabs v-model='cancelSubscriptionCodeExampleTab' class='code-examples-tabs'>
        <v-tab>Node.js</v-tab>
        <v-tab>PHP</v-tab>
      </v-tabs>

      <v-tabs-items v-model='cancelSubscriptionCodeExampleTab'>
        <v-tab-item>
          <NodeCancelSubscriptionExample />
        </v-tab-item>
        <v-tab-item>
          <PhpCancelSubscriptionExample />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <v-card-text>
      <p class='text-break'><strong>Note:</strong> For more detailed information about possible
        responses and error handling, refer to the API reference section of this documentation.</p>
    </v-card-text>
  </section>
</template>

<script>
import {
  NodeCancelSubscriptionExample,
  PhpCancelSubscriptionExample,
} from './_partials/code-examples';
import { ref } from '@vue/composition-api';

export default {
  components: { NodeCancelSubscriptionExample, PhpCancelSubscriptionExample },
  setup() {
    const cancelSubscriptionCodeExampleTab = ref(null);

    return {
      cancelSubscriptionCodeExampleTab,
    };
  },
};
</script>
