<template>
  <section id='docs-subscriptions-integration-api-reference'>
    <v-card-title>
      <h3 class='text-break'>Complete API Reference</h3>
    </v-card-title>

    <ApiUrl />

    <ApiCreateEndpoint />

    <ApiProcessEndpoint />

    <ApiDetailsEndpoint />

    <ApiChargeEndpoint />

    <ApiCancelEndpoint />

    <ApiRenewEndpoint />
  </section>
</template>

<script>
import {
  ApiCancelEndpoint,
  ApiChargeEndpoint,
  ApiCreateEndpoint,
  ApiDetailsEndpoint,
  ApiProcessEndpoint,
  ApiRenewEndpoint,
  ApiUrl,
} from './_partials/api-endpoints';

export default {
  components: {
    ApiCancelEndpoint,
    ApiChargeEndpoint,
    ApiCreateEndpoint,
    ApiDetailsEndpoint,
    ApiProcessEndpoint,
    ApiRenewEndpoint,
    ApiUrl,
  },
};
</script>
