<template>
  <section id='docs-subscriptions-integration-create-payment-session'>
    <v-card-title>
      <h4 class='text-break'>&#10122; Create Payment Session</h4>
    </v-card-title>

    <v-card-subtitle>
      To initiate the process of subscription creation and first payment charge, begin by creating a
      Payment Session object within the
      Merchanto system. This can be achieved by sending a server-side API request to
      Merchanto. Ensure that the request includes pertinent details about the impending
      payment, such as the order ID, payment amount, sum, currency, frequency of the future payments
      and the date when subscription will end. Following the
      successful processing of this API request, a time-limited session will be established
      on our end, and the corresponding Payment Session ID will be returned as part of the API
      response. To commence the payment process, begin by creating a Payment Session object
      within the Merchanto system. Follow these steps to achieve this:
    </v-card-subtitle>

    <v-card-title>Send a Server-Side API Request:</v-card-title>
    <v-card-text>
      <ul>
        <li>Initiate the process by sending a server-side API request to Merchanto.</li>
        <li>Ensure that the request contains crucial details related to the impending
          payment, including:
          <ul>
            <li>Order ID</li>
            <li>Payment amount (expressed in any valid format, e.g., 100 or 9.99)</li>
            <li>Currency (currently supporting USD and EUR)</li>
            <li>Frequency of Subscription payments - indicates the number of days between
              charges. Merchanto platform
              does not process or validate this parameter; it is used only for payment providers.
              This field defines the interval between subscription charges, allowing you to
              customize the billing cycle according to your business model. If the charging
              frequency is not fixed or known in advance, you can set the value to <code>0</code>
            </li>
            <li>The final day of the subscription, indicating when the subscription ends and it must
              be set to a date at least one month beyond the current date. Once the expiration date
              is reached, the subscription will automatically conclude, and there is no option to
              extend an ended subscription. To continue the subscription service, a new subscription
              must be created, ensuring a seamless and compliant renewal process
            </li>
            <li>Transaction description (optional but can be included for system and payment
              provider reference)
            </li>
            <li>Redirect URL (your site URL for post-payment processing redirection)</li>
          </ul>
        </li>
      </ul>
    </v-card-text>

    <v-card-title>Endpoint and HTTP Method:</v-card-title>
    <v-card-text>
      <ul>
        <li>Utilize the HTTP POST method to send the request to the <code>/payment-subscriptions/create</code>
          endpoint.
        </li>
      </ul>
    </v-card-text>

    <v-card-title>Include API Key in Header:</v-card-title>
    <v-card-text>
      <ul>
        <li>
          Attach the <code>'Api-Key'</code> header with your Merchanto platform API key.
        </li>
      </ul>
    </v-card-text>

    <v-card-title>Minimum Required Fields:</v-card-title>
    <v-card-text>
      <ul>
        <li>Ensure the inclusion of the following minimum required fields in your request:
          <ul>
            <li><code>amount</code>: The amount of the transaction (with or without cents). Also can be 0 (in case of some trial period)
            </li>
            <li><code>currency</code>: The currency of the future transaction (supporting
              USD and EUR).
            </li>
            <li><code>orderId</code>: Any string representing the ID of the current order in
              your system.
            </li>
            <li><code>frequency</code>: Number representing the number of days between
              charges
            </li>
            <li><code>expiresAt</code>: string with Date in ISO8601 format, representing the final
              day of the subscription
            </li>
            <li><code>transactionDescription</code>: An optional field for system and
              payment provider reference.
            </li>
            <li><code>redirectUrl</code>: Your site URL for redirection upon payment
              processing completion, including payment status, order ID, and payment ID.
            </li>
          </ul>
        </li>
      </ul>
    </v-card-text>

    <v-card-title>Session Creation and Response:</v-card-title>
    <v-card-text>
      <ul>
        <li>Upon successful processing of the API request, a time-limited session will be
          established on our end.
        </li>
        <li>The corresponding Payment Session ID will be returned as part of the API response.</li>
      </ul>
    </v-card-text>

    <v-card-text>
      <strong>Important:</strong> The Payment Session created at Merchanto platform has a limited
      lifetime of 10 minutes, implemented with security in mind to safeguard sensitive payment data.
      If a processing attempt occurs more than 10 minutes after the session creation, an error will
      be triggered, indicating that the session has expired. It is essential to manage the timing of
      payment processing to avoid issues related to expired sessions. Every unsuccessful attempt to
      process the payment automatically extends the session by an additional 10 minutes. For
      instance, if a customer makes a mistake in the card number during the payment attempt,
      resulting in a failure, the session will be extended. This approach allows sufficient time to
      correct mistakes and make another payment attempt while ensuring a smooth and responsive
      payment experience.
    </v-card-text>

    <v-card-text>
      <strong>Note:</strong> You can use the provided <code>orderId</code> to identify and
      manage orders in your system, updating their status or sending confirmation emails as
      needed.
    </v-card-text>

    <v-card-title><span class='subtitle-1'>Code example:</span></v-card-title>
    <v-card-text>
      <v-tabs v-model='codeExampleTab' class='code-examples-tabs'>
        <v-tab>Node.js</v-tab>
        <v-tab>PHP</v-tab>
      </v-tabs>

      <v-tabs-items v-model='codeExampleTab'>
        <v-tab-item>
          <NodeCreatePaymentSessionExample />
        </v-tab-item>
        <v-tab-item>
          <PhpCreatePaymentSessionExample />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </section>
</template>

<script>
import {
  NodeCreatePaymentSessionExample,
  PhpCreatePaymentSessionExample,
} from '../code-examples';
import { ref } from '@vue/composition-api';

export default {
  components: { NodeCreatePaymentSessionExample, PhpCreatePaymentSessionExample },
  setup() {
    const codeExampleTab = ref(null);

    return {
      codeExampleTab,
    };
  },
};
</script>
