<template>
  <section id='docs-subscriptions-charge-recurrent-payment'>
    <v-card-title>
      <h3 class='text-break'>Charge Recurrent Payment</h3>
    </v-card-title>

    <v-card-subtitle>
      This part guides you through the process of charging recurrent payments for subscriptions
      using the Merchanto platform. As a developer implementing the subscription flow, it is crucial
      to handle the logic for the frequency of charges and the payment amount.
    </v-card-subtitle>

    <v-card-title>Initiating Recurrent Payment:</v-card-title>
    <v-card-text>
      <ul class='mb-2'>
        <li>To initiate a recurrent payment for a subscription, you need to make a request to the
          <code>/payment-subscriptions/charge</code> endpoint. This request should include the
          following parameters:
          <ul>
            <li><code>subscriptionToken</code> - The unique identifier for the subscription
              generated by the Merchanto platform
            </li>
            <li><code>orderId</code> - An identifier provided by your platform</li>
            <li><code>amount</code> - The amount to be charged for the recurrent payment. This value
              should be positive and greater than zero. This value can differ from initial payment
              amount
            </li>
            <li><code>currency</code> - The currency for the payment. Currently, only Euro and US
              Dollar are supported. Generally, this value should be equal with initial payment
              currency
            </li>
          </ul>
        </li>
      </ul>
      <pre class='output'><code v-pre>
    {
      "subscriptionToken": "5a8e797a-0363-40e4-9dee-a78e541b7835",
      "orderId": "b77a4b5c-9037-11ee-b9d1-0242ac120002",
      "amount": "9.99",
      "currency": "EUR",
    }
      </code></pre>
    </v-card-text>

    <v-card-title>Handling Response:</v-card-title>
    <v-card-text>
      <ul class='mb-2'>
        <li>Upon successfully initiating the recurrent payment, the response from the Merchanto
          platform will include details such as:
          <ul>
            <li><code>subscriptionToken</code> - The unique identifier for the subscription
              generated by the Merchanto platform
            </li>
            <li><code>subscriptionStatus</code> - Current status of the subscription</li>
            <li><code>paymentId</code> - The ID of the payment generated by the Merchanto platform
            </li>
            <li><code>orderId</code> - The original order ID provided by your system during
              recurrent payment initiating
            </li>
            <li><code>paymentStatus</code> - The status of the recurrent payment</li>
          </ul>
        </li>
      </ul>
      <pre class='output'><code v-pre>
    {
      "subscriptionToken": "5a8e797a-0363-40e4-9dee-a78e541b7835",
      "subscriptionStatus": "active",
      "paymentId": "9a8e797a-0363-40e4-9dee-a78e541b7810",
      "orderId": "b77a4b5c-9037-11ee-b9d1-0242ac120002",
      "paymentStatus": "completed"
    }
      </code></pre>
    </v-card-text>

    <v-card-title><span class='subtitle-1'>Code example:</span></v-card-title>
    <v-card-text>
      <v-tabs v-model='recurrentPaymentCodeExampleTab' class='code-examples-tabs'>
        <v-tab>Node.js</v-tab>
        <v-tab>PHP</v-tab>
      </v-tabs>

      <v-tabs-items v-model='recurrentPaymentCodeExampleTab'>
        <v-tab-item>
          <NodeChargeRecurrentPaymentExample />
        </v-tab-item>
        <v-tab-item>
          <PhpChargeRecurrentPaymentExample />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <v-card-title>Handling Unsuccessful Recurrent Payments:</v-card-title>
    <v-card-text>
      <ul class='mb-2'>
        <li>In cases where recurrent payment attempts are unsuccessful, and there is no resolution
          within your system, the subscription status will be automatically set to <code><strong>"paused"</strong></code>.
          This status indicates that the latest attempt to charge a recurrent payment was
          unsuccessful, and the subscription is temporarily paused.
        </li>
      </ul>
    </v-card-text>

    <v-card-title>Reactivating a Paused Subscription:</v-card-title>
    <v-card-text>
      <ul class='mb-2'>
        <li>If a subscription is in a paused state, you should investigate the reason for the
          unsuccessful payment attempts and address any issues within your system. Once the
          necessary corrections are made, you can reactivate the subscription, allowing recurrent
          payments to resume.
        </li>
      </ul>
    </v-card-text>

    <v-card-text>
      <p class='text-break'><strong>Important:</strong> It's essential to be aware that some payment
        providers may impose restrictions on recurrent payments if there are too few days between
        recurrent charges. For instance, if the initial frequency parameter provided during
        subscription creation is too short, the payment provider may block the subsequent recurrent
        payment. Ensure that the initial frequency aligns with the payment provider's policies.</p>

      <p class='text-break'><strong>Note:</strong> For more detailed information about possible
        responses and error handling, refer to the API reference section of this documentation.</p>
    </v-card-text>
  </section>
</template>

<script>
import {
  NodeChargeRecurrentPaymentExample,
  PhpChargeRecurrentPaymentExample,
} from './_partials/code-examples';
import { ref } from '@vue/composition-api';

export default {
  components: { NodeChargeRecurrentPaymentExample, PhpChargeRecurrentPaymentExample },
  setup() {
    const recurrentPaymentCodeExampleTab = ref(null);

    return {
      recurrentPaymentCodeExampleTab,
    };
  },
};
</script>
